<template>
	<div class="c-register">
		<div class="o-container o-container--full">
			<div class="grid">
				<div class="row">
					<div class="col-md-6">
						<div class="c-register__left">
							<div class="c-register__switch">
								Ben je al lid?
								<router-link :to="{ name: 'Login' }" title="Sign in"> Meld je aan </router-link>
							</div>
							<!-- STEP 1: ACCOUNT TYPE -->
							<div>
								<div class="c-register__form">
									<h1>Registreren</h1>
									<InputComponent v-model="form.name" label="Naam" autocomplete="name" />
									<InputComponent
										v-model="form.email"
										input-type="email"
										label="E-mailadres"
										autocomplete="email"
									/>
									<div v-if="errors.email" class="c-register__form--error">
										{{ errors.email }}
									</div>
									<InputComponent
										v-model="form.password"
										input-type="password"
										label="Wachtwoord"
										autocomplete="new-password"
									/>
									<div v-if="errors.password" class="c-register__form--error">
										{{ errors.password }}
									</div>
									<div class="c-register__terms">
										<input id="Consent" v-model="form.consent" type="checkbox" name="Consent" />
										<label for="Consent"
											>Ik ga akkoord met de
											<router-link
												:to="{ name: 'TermsAndConditions' }"
												title="Algemene voorwaarden"
											>
												algemene voorwaarden
											</router-link></label
										>
										<button class="c-button" @click="createAccount()">Account aanmaken</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6 u-tablet-hide">
						<div class="c-register__right">
							<div class="c-register__logo">
								<img src="~@/assets/images/logo-blue.svg" alt="" />
							</div>
							<div class="c-register__visual">
								<img src="~@/assets/images/login-visual.svg" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Register.controller.js"></script>
<style lang="scss" src="./Register.styles.scss"></style>
