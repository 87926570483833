import { InputComponent } from "@/components";
import { mapActions } from "vuex";

export default {
	name: "Register",
	components: {
		InputComponent,
	},
	data: () => ({
		example: true,
		form: {
			name: "",
			email: "",
			password: "",
			accountType: "",
			consent: false,
		},
		errors: {
			email: "",
		},
		regex: {
			email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
			password: /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/,
		},
	}),
	methods: {
		...mapActions("account", {
			registerAction: "REGISTER",
		}),
		// nextStep() {
		// 	if (this.form.name && this.form.email && this.form.password && this.form.consent) {
		// 		if(this.regex.email.test(this.form.email)) {
		// 			this.errors.email = "";
		// 		}
		// 		else {
		// 			this.errors.email = "Vul een geldig e-mailadres in";
		// 		}
		// 		if(this.regex.password.test(this.form.password)) {
		// 			this.errors.password = "";
		// 			this.stepIndex += 1;
		// 		}
		// 		else {
		// 			this.errors.password = "Een wachtwoord moet bestaan uit 8 karakters, met minstens 1 letter en 1 nummer";
		// 			return;
		// 		}

		// 	}
		// },
		createAccount() {
			if (this.form.accountType) {
				const payload = {
					first_name: this.form.name,
					last_name: "",
					email: this.form.email,
					type: this.form.accountType,
					password: this.form.password,
					company_name: "",
					address: {
						country: "",
						street: "",
						number: "",
						city: "",
						zip_code: "",
					},
				};

				this.registerAction(payload).then(() => {
					this.$router.push({ name: "Login" });
				});
			}
		},
	},
};
